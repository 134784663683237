<template>
  <div v-if="this.items.length > 0">
    <h2 class="mb-4">Népszerű nyugdíjmegtakarítási termékek</h2>
    <div class="row mb-4">
      <div class="col-md-4" v-for="(item, index) in (this.items)" :key="index">
        <router-link :to="addHash(item.product)">
          <div class="card">
            <div class="position-relative">
              <img src="assets/img/play.png" class="img-fluid play"/>
              <img v-if="item.image !== null" class="card-img-top"
                   v-bind:src="item.image"
                   v-bind:alt="item.name"/>
            </div>
            <div class="card-body">
              <h5 class="card-title">{{ item.name }}</h5>
              <div class="card-text" v-html="item.content"/>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import CmsApiService from "@/services/CmsApiService";
import moment from 'moment';

export default {
  name: "BasicQuestions",
  data() {
    return {
      items: "",
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      await CmsApiService.getContent('home-articles/7/3')
          .then(response => {
            if (response) {
              this.items = response.data;
            }
          });
    },
    moment: function () {
      return moment();
    },
    addHash(url) {
      return this.$addHashParameter(url);
    }
  },
  filters: {
    moment: function (date) {
      return moment(date).format('YYYY.MM.DD');
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 bv-d-sm-down-none contact"></div>
      <div class="col-md-6 form-wrapper">
        <div class="recall-form">
          <div class="row mb-3">
            <div class="col-9">
              <h2>Visszahívjuk!</h2>
              <div v-if="this.consultant.name" style="color: #00427c;">
                Az Ön személyes tanácsadója:
                <span
                    class="consultant-name"
                    @click="showModal('consultant')"
                >{{ this.consultant.name }}</span
                >
              </div>
            </div>
            <div class="col-3">
              <img
                  v-if="this.consultant.image"
                  v-bind:src="'data:image/jpeg;base64,' + this.consultant.image"
                  class="img-responsive consultant-image link"
                  @click="showModal('consultant')"
              />
            </div>
          </div>
          <p>
            Kérjük, adja meg elérhetőségét, hogy kapcsolatba tudjunk Önnel
            lépni!
          </p>
          <b-form ref="recallForm" @submit.prevent="postForm">
            <b-form-row>
              <b-form-group class="col-lg-6" id="lastname">
                <b-form-input
                    v-model="$v.form.lastname.$model"
                    type="text"
                    placeholder="Vezetéknév"
                    name="lastname"
                    :state="validateState('lastname')"
                    maxlength="100"
                ></b-form-input>
              </b-form-group>

              <b-form-group class="col-lg-6" id="firstname">
                <b-form-input
                    v-model="$v.form.firstname.$model"
                    type="text"
                    placeholder="Keresztnév"
                    name="firstname"
                    :state="validateState('firstname')"
                    maxlength="100"
                ></b-form-input>
              </b-form-group>
            </b-form-row>

            <b-form-row>
              <b-form-group class="col-lg-6" id="zip">
                <b-form-input
                    v-model="$v.form.zip.$model"
                    type="text"
                    placeholder="Irányítószám"
                    name="zip"
                    maxlength="4"
                    :state="validateState('zip')"
                ></b-form-input>
              </b-form-group>

              <b-form-group class="col-lg-6" id="phone">
                <b-form-input
                    v-model="$v.form.phone.$model"
                    type="text"
                    placeholder="Telefonszám"
                    name="phone"
                    :state="validateState('phone')"
                    maxlength="20"
                ></b-form-input>
              </b-form-group>
            </b-form-row>

            <b-form-row>
              <b-form-group class="col-12" id="time">
                <b-form-select
                    v-model="$v.form.time.$model"
                    name="time"
                    :options="this.formData.timeOptions"
                    class="form-control custom-select"
                    :state="validateState('time')"
                ></b-form-select>
              </b-form-group>
            </b-form-row>
            <b-form-row>
              <b-form-group class="col-12" id="agentname">
                <b-form-input
                    v-model="$v.form.agentname.$model"
                    type="text"
                    placeholder="OVB tanácsadó neve (ha van)"
                    name="agentname"
                    maxlength="100"
                    :value="($v.form.agentname.$model = cName)"
                    :readonly="isReadonly"
                ></b-form-input>
                <b-form-input
                    v-model="$v.form.agenthash.$model"
                    type="text"
                    name="agenthash"
                    maxlength="100"
                    :value="($v.form.agenthash.$model = cHash)"
                    style="display: none"
                    readonly
                ></b-form-input>
              </b-form-group>
            </b-form-row>
            <b-form-row>
              <b-form-group class="col-12" id="notes">
                <b-form-textarea
                    v-model="$v.form.notes.$model"
                    placeholder="Megjegyzés"
                    name="notes"
                    rows="3"
                    maxlength="1000"
                ></b-form-textarea>
              </b-form-group>
            </b-form-row>

            <b-form-row>
              <b-form-group class="col-12" id="privacy">
                <b-form-checkbox
                    v-model="$v.form.privacy.$model"
                    name="privacy"
                    :state="validateState('privacy')"
                >
                  Az OVB Vermögensberatung Kft.
                  <a
                      href="https://ovbportal.hu/ovbphp/public/dokumentumtar/dokumentumAktualisVerzio.php?dokumentumID=20"
                      target="_blank"
                  >Adatkezelési Szabályzatát</a
                  >
                  és a vonatkozó
                  <a
                      href="https://ovbportal.hu/ovbphp/public/dokumentumtar/dokumentumAktualisVerzio.php?dokumentumID=9"
                      target="_blank"
                  >Adatkezelési tájékoztatót</a
                  >
                  megismertem és elfogadom, a megadott adataim kezeléséhez
                  hozzájárulok.
                </b-form-checkbox>
              </b-form-group>
            </b-form-row>
            <b-form-row>
              <b-button
                  type="submit"
                  variant="btn btn-primary text-center ml-auto mr-auto"
                  :disabled="!this.form.privacy"
              >Visszahívást kérek!
              </b-button>
            </b-form-row>
          </b-form>
          <b-modal
              ref="success-modal"
              hide-footer
              title="Köszönjük érdeklődését!"
          >
            <div class="d-block text-center">
              <h3>
                Hamarosan hívni fogjuk az Ön által megadott telefonszámon!
              </h3>
            </div>
            <b-button
                class="mt-3"
                variant="outline-success"
                block
                @click="hideModal('success')"
            >Bezár
            </b-button
            >
          </b-modal>

          <b-modal ref="error-modal" hide-footer title="Hiba történt!">
            <div class="d-block text-center">
              <h3>Hiba történt az űrlap beküldése során!</h3>
              <validation-error
                  :errors="validationErrors"
                  v-if="validationErrors"
              ></validation-error>
            </div>
            <b-button
                class="mt-3"
                variant="outline-danger"
                block
                @click="hideModal('error')"
            >Bezár
            </b-button
            >
          </b-modal>

          <b-modal
              v-if="this.consultant.data"
              ref="consultant-modal"
              hide-footer
              id="consultant-modal"
          >
            <div class="consultant">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="bg-effects">
                        <h2 class="card-title">{{ this.consultant.name }}</h2>
                        <p v-if="this.consultant.company" class="card-text">
                          {{ this.consultant.data.cegnev }}
                        </p>
                      </div>
                      <ul v-if="this.consultant.company">
                        <li>
                          Az OVB közvetítői megbízottjaként eljáró Tanácsadó
                          adatai
                        </li>
                        <li>
                          Név: <span>{{ this.consultant.data.cegnev }}</span>
                        </li>
                        <li
                            v-if="
                            this.consultant.data
                              .cegBiztositaskozvetitoiRegisztraciosSzam.length >
                              0
                          "
                        >
                          Biztosításközvetítői felügyeleti nyilvántartási szám:
                          <span>{{
                              this.consultant.data
                                  .cegBiztositaskozvetitoiRegisztraciosSzam
                            }}</span>
                        </li>
                        <li
                            v-if="
                            this.consultant.data.cegHitelRegisztraciosSzam
                              .length > 0
                          "
                        >
                          Pénzügyi szolgáltatás közvetítői felügyeleti
                          nyilvántartási szám:
                          <span>{{
                              this.consultant.data.cegHitelRegisztraciosSzam
                            }}</span>
                        </li>
                        <li v-if="this.consultant.data.cegSzekhely.length > 0">
                          Székhely:
                          <span>{{ this.consultant.data.cegSzekhely }}</span>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          Közvetítői tevékenységet végző természetes személy
                        </li>
                        <li>
                          Név:
                          <span>{{
                              this.consultant.data.termeszetesSzemelyNev
                            }}</span>
                        </li>
                        <li>
                          Biztosításközvetítői felügyeleti nyilvántartási szám:
                          <span>{{
                              this.consultant.data
                                  .termeszetesSzemelyRegisztraciosSzam
                            }}</span>
                        </li>
                      </ul>
                    </div>
                    <div class="col-4 pl-0 d-none d-md-block">
                      <img
                          v-if="this.consultant.image"
                          v-bind:src="
                          'data:image/jpeg;base64,' + this.consultant.image
                        "
                          class="img-responsive consultant-image"
                      />
                    </div>
                  </div>
                </div>
                <div class="bg-custom text-center">
                  <ul>
                    <li>Az OVB adatai</li>
                    <li>
                      Biztosításközvetítői felügyeleti nyilvántartási szám:
                      <span>{{
                          this.consultant.data
                              .ovbBiztositaskozvetitoiRegisztraciosSzam
                        }}</span>
                    </li>
                    <li>
                      Pénzügyi szolgáltatás közvetítői felügyeleti
                      nyilvántartási szám:
                      <span>{{
                          this.consultant.data
                              .ovbPenzugyiKozvetitoiRegisztraciosSzam
                        }}</span>
                    </li>
                    <li>
                      Székhely:
                      <span>{{ this.consultant.data.ovbSzekhely }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
    <div v-if="this.consultant.review" class="container my-2">
      <EmbedReviewWidget :refId="this.consultant.hash"/>
      <div v-html="this.consultant.review"/>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  minValue,
  integer,
  between,
  sameAs,
} from "vuelidate/lib/validators";
import OvbWebService from "@/services/OvbWebService";
import ValidationError from "@/components/partials/ValidationError";
import EmbedReviewWidget from "@/components/partials/EmbedReviewWidget";

export default {
  name: "RecallForm",
  components: {
    ValidationError,
    EmbedReviewWidget,
  },
  data() {
    return {
      consultant: {
        hash: null,
        data: null,
        name: null,
        company: null,
        image: null,
        review: null,
      },
      form: {
        lastname: "",
        firstname: "",
        zip: "",
        phone: "",
        time: 1,
        agentname: null,
        agenthash: null,
        notes: "",
        privacy: false,
      },
      formData: {
        timeOptions: [
          {value: "1", text: "Délelőtti hívást kérek (9-12 óra)"},
          {value: "2", text: "Délutáni hívást kérek (12-18 óra)"},
          {value: "3", text: "Esti hívást kérek (18 óra után)"},
        ],
      },
      validationErrors: "",
    };
  },
  validations: {
    form: {
      lastname: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100),
      },
      firstname: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100),
      },
      zip: {
        required,
        integer,
        maxLength: maxLength(4),
        minValue: minValue(1000),
      },
      phone: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(20),
      },
      time: {
        required,
        integer,
        between: between(1, 3),
      },
      agentname: {
        maxLength: maxLength(100),
      },
      agenthash: {
        maxLength: maxLength(100),
      },
      notes: {
        maxLength: maxLength(1000),
      },
      privacy: {
        required,
        sameAs: sameAs(() => true),
      },
    },
  },
  mounted() {
    this.getConsultantData();
  },
  methods: {
    showModal(ref) {
      this.$refs[ref + "-modal"].show();
    },
    hideModal(ref) {
      this.$refs[ref + "-modal"].hide();
    },
    validateState(name) {
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    postForm: function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      OvbWebService.postRecallForm(this.form)
          .then(response => {
            if (response.data.success) {
              if (response.data.success == 1) {
                this.showModal("success");
                this.form.lastname = "";
                this.form.firstname = "";
                this.form.zip = "";
                this.form.phone = "";
                this.form.time = 1;
                this.form.agentname = null;
                this.form.agenthash = null;
                this.form.notes = "";
                this.form.privacy = false;

                this.$refs.recallForm.reset();
              }
            } else {
              if (response.data.errors) {
                this.validationErrors = response.data.errors;
              }
              this.showModal("error");
            }
          });

      this.$nextTick(() => {
        this.$v.$reset()
      });


    },
    async getConsultantData() {
      if (this.$route.query.t) {
        const hash = this.$route.query.t;
        this.consultant.hash = hash;
        await OvbWebService.getConsultant(hash).then((response) => {
          if (response) {
            this.consultant.data = response.data;
            this.consultant.name = response.data.termeszetesSzemelyNev;
            this.consultant.company = response.data.cegnev;
            this.consultant.image = response.data.fenykep;
            this.consultant.review = response.data.reviewWidgetLink;
          }
        });
      }
    },
  },
  computed: {
    cName() {
      if (this.consultant.name) {
        return this.consultant.name;
      } else {
        return this.form.agentname;
      }
    },
    cHash() {
      return this.consultant.hash;
    },
    isReadonly() {
      if (this.consultant.name) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <div id="calculator-wrapper">
    <div class="calculator-body">
      <h3 class="mb-3">Kalkulátorunk segítségével kiszámolhatja, mekkora nyugdíjra számíthat!</h3>
      <b-form ref="calculatorForm" @submit.prevent="postForm">
        <b-form-row>
          <b-form-group class="col-md-6">
            <b-row class="my-1">
              <b-col sm="5">
                <label>Aktuális kor:</label>
              </b-col>
              <b-col sm="7">
                <b-row>
                  <b-col cols="10">
                    <b-form-input
                        type="range"
                        v-model="$v.form.ovbNyugdijAktualisKor.$model"
                        name="ovbNyugdijAktualisKor"
                        class="form-control"
                        min="23"
                        max="55"
                        step="1"
                        :state="validateState('ovbNyugdijAktualisKor')"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="2" class="text-right">
                    {{ $v.form.ovbNyugdijAktualisKor.$model }}
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form-group>

          <b-form-group class="col-md-6">
            <b-row class="my-1">
              <b-col sm="5">
                <label>Nem:</label>
              </b-col>
              <b-col sm="7">
                <b-form-select
                    v-model="$v.form.ovbNyugdijNem.$model"
                    name="ovbNyugdijNem"
                    :options="this.formData.ovbNyugdijNemOptions"
                    class="form-control custom-select"
                    :state="validateState('ovbNyugdijNem')"
                ></b-form-select>
              </b-col>
            </b-row>
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group class="col-md-6">
            <b-row class="my-1">
              <b-col sm="5">
                <label>Munkakezdési kor:</label>
              </b-col>
              <b-col sm="7">
                <b-row>
                  <b-col cols="10">
                    <b-form-input
                        type="range"
                        v-model="$v.form.ovbNyugdijMunkakezdesKor.$model"
                        name="ovbNyugdijMunkakezdesKor"
                        class="form-control"
                        min="16"
                        max="30"
                        step="1"
                        :state="validateState('ovbNyugdijMunkakezdesKor')"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="2" class="text-right">
                    {{ $v.form.ovbNyugdijMunkakezdesKor.$model }}
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form-group>

          <b-form-group class="col-md-6">
            <b-row class="my-1">
              <b-col sm="5">
                <label>Aktuális havi bruttó bér (nyugdíjjárulék fizetve):</label>
              </b-col>
              <b-col sm="7">
                <b-row>
                  <b-col cols="8">
                    <b-form-input
                        type="range"
                        v-model="$v.form.ovbNyugdijBruttoBerHaviKezdo.$model"
                        name="ovbNyugdijBruttoBerHaviKezdo"
                        class="form-control"
                        min="150000"
                        max="1500000"
                        step="10000"
                        :state="validateState('ovbNyugdijBruttoBerHaviKezdo')"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="4" class="text-right">
                    {{ localeNumber($v.form.ovbNyugdijBruttoBerHaviKezdo.$model) }}
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group class="col-md-6">
            <b-row class="my-1">
              <b-col sm="5">
                <label>Egyéb havi jövedelem (nincs nyugdíjjárulék):</label>
              </b-col>
              <b-col sm="7">
                <b-row>
                  <b-col cols="8">
                    <b-form-input
                        type="range"
                        v-model="$v.form.ovbNyugdijEgyebJovedelemKezdoHavi.$model"
                        name="ovbNyugdijMunkakezdesKor"
                        class="form-control"
                        min="0"
                        max="1500000"
                        step="10000"
                        :state="validateState('ovbNyugdijEgyebJovedelemKezdoHavi')"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="4" class="text-right">
                    {{ $v.form.ovbNyugdijEgyebJovedelemKezdoHavi.$model }}
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form-group>

          <b-form-group class="col-md-6 ">
            <b-row class="my-1">
              <b-col>
                <b-button type="submit" class="float-md-right" style="margin-top: -6px">Számolás!</b-button>
              </b-col>
            </b-row>
          </b-form-group>

          <b-form-group class="col-md-6 d-none">
            <b-row class="my-1">
              <b-col sm="5">
                <label>Demográfiai szorzó:</label>
              </b-col>
              <b-col sm="5">
                <b-form-input
                    type="range"
                    v-model="$v.form.ovbNyugdijDemografiaiSzorzo.$model"
                    name="ovbNyugdijDemografiaiSzorzo"
                    class="form-control"
                    min="0.7"
                    max="1"
                    step="0.01"
                    :state="validateState('ovbNyugdijDemografiaiSzorzo')"
                ></b-form-input>
              </b-col>
              <b-col sm="2">
                {{ $v.form.ovbNyugdijDemografiaiSzorzo.$model }}
              </b-col>
            </b-row>
          </b-form-group>
          </b-form-row>

          <b-form-row class="d-none">
            <b-form-group class="col-md-6">
              <b-row class="my-1">
                <b-col sm="5">
                  <label>SZJA Kedvezmény:</label>
                </b-col>
                <b-col sm="5">
                  <b-form-select
                      v-model="$v.form.ovbNyugdijMegtakaritasSzjaKedvezmeny.$model"
                      name="ovbNyugdijMegtakaritasSzjaKedvezmeny"
                      :options="this.formData.ovbNyugdijMegtakaritasSzjaKedvezmeny"
                      class="form-control custom-select"
                      :state="validateState('ovbNyugdijMegtakaritasSzjaKedvezmeny')"
                  ></b-form-select>
                </b-col>
                <b-col sm="2">
                  {{ $v.form.ovbNyugdijMegtakaritasSzjaKedvezmeny.$model }}
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group class="col-md-6">
              <b-row class="my-1">
                <b-col sm="5">
                  <label> Megtakarítás javaslat számítása:</label>
                </b-col>
                <b-col sm="7">
                  <b-form-select
                      v-model="$v.form.ovbNyugdijMegtakaritasJavaslat.$model"
                      name="ovbNyugdijNem"
                      :options="this.formData.ovbNyugdijMegtakaritasSzjaKedvezmeny"
                      class="form-control custom-select"
                      :state="validateState('ovbNyugdijMegtakaritasJavaslat')"
                  ></b-form-select>
                </b-col>
              </b-row>
            </b-form-group>
          </b-form-row>

          <b-form-row class="d-none">
            <b-form-group class="col-md-6">
              <b-row class="my-1">
                <b-col sm="5">
                  <label>Kezdő havi megtakarítás (ha nincs javaslat):</label>
                </b-col>
                <b-col sm="5">
                  <b-form-input
                      type="range"
                      v-model="$v.form.ovbNyugdijMegtakaritasOsszegKezdoHavi.$model"
                      name="ovbNyugdijMegtakaritasOsszegKezdoHavi"
                      class="form-control"
                      min="15000"
                      max="100000"
                      step="1000"
                      :state="validateState('ovbNyugdijMegtakaritasOsszegKezdoHavi')"
                  ></b-form-input>
                </b-col>
                <b-col sm="2">
                  {{ $v.form.ovbNyugdijMegtakaritasOsszegKezdoHavi.$model }}
                </b-col>
              </b-row>
            </b-form-group>

          </b-form-row>
      </b-form>
    </div>
    <b-modal ref="error-modal" hide-footer title="Hiba történt!">
      <div class="d-block text-center">
        <h3>Hiba történt az űrlap beküldése során!</h3>
        <validation-error :errors="validationErrors" v-if="validationErrors"></validation-error>
      </div>
      <b-button class="mt-3" variant="outline-danger" block @click="hideModal('error')">Bezár</b-button>
    </b-modal>
  </div>
</template>

<script>
import {between, integer, decimal, required} from "vuelidate/lib/validators";
import CmsApiService from "@/services/CmsApiService";
import ValidationError from "@/components/partials/ValidationError";

export default {
  name: "Calculator",
  components: {ValidationError},
  data() {
    return {
      form: {
        ovbNyugdijAktualisKor: 42,
        ovbNyugdijNem: 1,
        ovbNyugdijMunkakezdesKor: 21,
        ovbNyugdijBruttoBerHaviKezdo: 500000,
        ovbNyugdijEgyebJovedelemKezdoHavi: 0,
        ovbNyugdijDemografiaiSzorzo: 1,
        ovbNyugdijMegtakaritasSzjaKedvezmeny: 1,
        ovbNyugdijMegtakaritasJavaslat: 1,
        ovbNyugdijMegtakaritasOsszegKezdoHavi: 30000,
      },
      formData: {
        ovbNyugdijNemOptions: [
          {value: 1, text: 'Férfi'},
          {value: 2, text: 'Nő'},
        ],
        ovbNyugdijMegtakaritasSzjaKedvezmeny: [
          {value: 1, text: 'Igen'},
          {value: 0, text: 'Nem'},
        ],
      },
      validationErrors: '',
    }
  },
  validations: {
    form: {
      ovbNyugdijAktualisKor: {
        required,
        integer,
        between: between(23, 55)
      },
      ovbNyugdijNem: {
        required,
        integer,
        between: between(1, 2)
      },
      ovbNyugdijMunkakezdesKor: {
        required,
        integer,
        between: between(16, 30)
      },
      ovbNyugdijBruttoBerHaviKezdo: {
        required,
        integer,
        between: between(150000, 1500000)
      },
      ovbNyugdijEgyebJovedelemKezdoHavi: {
        required,
        integer,
        between: between(0, 1500000)
      },
      ovbNyugdijDemografiaiSzorzo: {
        required,
        decimal,
        between: between(0.07, 1)
      },
      ovbNyugdijMegtakaritasSzjaKedvezmeny: {
        required,
        integer,
        between: between(0, 1)
      },
      ovbNyugdijMegtakaritasJavaslat: {
        required,
        integer,
        between: between(0, 1)
      },
      ovbNyugdijMegtakaritasOsszegKezdoHavi: {
        required,
        integer,
        between: between(15000, 100000)
      },

    }
  },
  methods: {
    localeNumber(number) {
      return number.toLocaleString('hu-HU');
    },
    showModal(ref) {
      this.$refs[ref + "-modal"].show()
    },
    hideModal(ref) {
      this.$refs[ref + "-modal"].hide()
    },
    validateState(name) {
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    postForm: function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return
      }

      CmsApiService.postCalculatorForm(this.form)
          .then(response => {
            if (response.data.errors) {
              this.validationErrors = response.data.errors;
              this.showModal("error");
            } else {
              localStorage.results = JSON.stringify(response.data);
              localStorage.form = JSON.stringify(this.form);
              this.$router.push({path: this.$addHashParameter("/eredmeny")});
            }
          });
    }
  }
}

</script>

<style scoped>

</style>

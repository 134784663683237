<template>
  <div>
    <div
      class="inner-head"
      v-bind:style="{ backgroundImage: 'url(' + this.headerImage + ')' }"
    >
      <h1>Az Ön nyugdíjszámítása</h1>
    </div>
    <div class="container inner mt-3">
      <div id="calculator-wrapper">
        <div class="calculator-body">
          <h3 class="mb-3">
            Kalkulátorunk segítségével kiszámolhatja, mekkora nyugdíjra
            számíthat!
          </h3>
          <b-form ref="calculatorForm" @change="postForm">
            <b-form-row>
              <b-form-group class="col-md-6">
                <b-row class="my-1">
                  <b-col sm="5">
                    <label>Aktuális kor:</label>
                  </b-col>
                  <b-col sm="7">
                    <b-row>
                      <b-col cols="10">
                        <b-form-input
                          type="range"
                          v-model="$v.form.ovbNyugdijAktualisKor.$model"
                          name="ovbNyugdijAktualisKor"
                          class="form-control"
                          min="23"
                          max="55"
                          step="1"
                          :state="validateState('ovbNyugdijAktualisKor')"
                        ></b-form-input>
                      </b-col>
                      <b-col cols="2" class="text-right">
                        {{ $v.form.ovbNyugdijAktualisKor.$model }}
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-form-group>

              <b-form-group class="col-md-6">
                <b-row class="my-1">
                  <b-col sm="5">
                    <label>Nem:</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="$v.form.ovbNyugdijNem.$model"
                      name="ovbNyugdijNem"
                      :options="this.formData.ovbNyugdijNemOptions"
                      class="form-control custom-select"
                      :state="validateState('ovbNyugdijNem')"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-form-row>

            <b-form-row>
              <b-form-group class="col-md-6">
                <b-row class="my-1">
                  <b-col sm="5">
                    <label>Munkakezdési kor:</label>
                  </b-col>
                  <b-col sm="7">
                    <b-row>
                      <b-col cols="10">
                        <b-form-input
                          type="range"
                          v-model="$v.form.ovbNyugdijMunkakezdesKor.$model"
                          name="ovbNyugdijMunkakezdesKor"
                          class="form-control"
                          min="16"
                          max="30"
                          step="1"
                          :state="validateState('ovbNyugdijMunkakezdesKor')"
                        ></b-form-input>
                      </b-col>
                      <b-col cols="2" class="text-right">
                        {{ $v.form.ovbNyugdijMunkakezdesKor.$model }}
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-form-group>

              <b-form-group class="col-md-6">
                <b-row class="my-1">
                  <b-col sm="5">
                    <label
                      >Aktuális havi bruttó bér (nyugdíjjárulék fizetve):</label
                    >
                  </b-col>
                  <b-col sm="7">
                    <b-row>
                      <b-col cols="8">
                        <b-form-input
                          type="range"
                          v-model="$v.form.ovbNyugdijBruttoBerHaviKezdo.$model"
                          name="ovbNyugdijBruttoBerHaviKezdo"
                          class="form-control"
                          min="150000"
                          max="1500000"
                          step="10000"
                          :state="validateState('ovbNyugdijBruttoBerHaviKezdo')"
                        ></b-form-input>
                      </b-col>
                      <b-col cols="4" class="text-right">
                        {{ $v.form.ovbNyugdijBruttoBerHaviKezdo.$model }}
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-form-row>

            <b-form-row>
              <b-form-group class="col-md-6">
                <b-row class="my-1">
                  <b-col sm="5">
                    <label>Egyéb havi jövedelem (nincs nyugdíjjárulék):</label>
                  </b-col>
                  <b-col sm="7">
                    <b-row>
                      <b-col cols="8">
                        <b-form-input
                          type="range"
                          v-model="
                            $v.form.ovbNyugdijEgyebJovedelemKezdoHavi.$model
                          "
                          name="ovbNyugdijMunkakezdesKor"
                          class="form-control"
                          min="0"
                          max="1500000"
                          step="10000"
                          :state="
                            validateState('ovbNyugdijEgyebJovedelemKezdoHavi')
                          "
                        ></b-form-input>
                      </b-col>
                      <b-col cols="4" class="text-right">
                        {{ $v.form.ovbNyugdijEgyebJovedelemKezdoHavi.$model }}
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-form-group>

              <b-form-group class="col-md-6 d-none">
                <b-row class="my-1">
                  <b-col sm="5">
                    <label>Demográfiai szorzó:</label>
                  </b-col>
                  <b-col sm="7">
                    <b-row>
                      <b-col cols="10">
                        <b-form-input
                          type="range"
                          v-model="$v.form.ovbNyugdijDemografiaiSzorzo.$model"
                          name="ovbNyugdijDemografiaiSzorzo"
                          class="form-control"
                          min="0.7"
                          max="1"
                          step="0.01"
                          :state="validateState('ovbNyugdijDemografiaiSzorzo')"
                        ></b-form-input>
                      </b-col>
                      <b-col cols="2" class="text-right">
                        {{ $v.form.ovbNyugdijDemografiaiSzorzo.$model }}
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-form-row>

            <b-form-row class="d-none">
              <b-form-group class="col-md-6">
                <b-row class="my-1">
                  <b-col sm="5">
                    <label>SZJA Kedvezmény:</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="
                        $v.form.ovbNyugdijMegtakaritasSzjaKedvezmeny.$model
                      "
                      name="ovbNyugdijMegtakaritasSzjaKedvezmeny"
                      :options="
                        this.formData.ovbNyugdijMegtakaritasSzjaKedvezmeny
                      "
                      class="form-control custom-select"
                      :state="
                        validateState('ovbNyugdijMegtakaritasSzjaKedvezmeny')
                      "
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-form-group>

              <b-form-group class="col-md-6">
                <b-row class="my-1">
                  <b-col sm="5">
                    <label> Az Ön nyugdíjaszámítása:</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="$v.form.ovbNyugdijMegtakaritasJavaslat.$model"
                      name="ovbNyugdijNem"
                      :options="
                        this.formData.ovbNyugdijMegtakaritasSzjaKedvezmeny
                      "
                      class="form-control custom-select"
                      :state="validateState('ovbNyugdijMegtakaritasJavaslat')"
                      @change="toggle"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-form-row>

            <b-form-row class="d-none">
              <b-form-group class="col-md-6">
                <b-row class="my-1">
                  <b-col sm="5">
                    <label>Kezdő havi megtakarítás (ha nincs javaslat):</label>
                  </b-col>
                  <b-col sm="7">
                    <b-row>
                      <b-col cols="8">
                        <b-form-input
                          type="range"
                          v-model="
                            $v.form.ovbNyugdijMegtakaritasOsszegKezdoHavi.$model
                          "
                          name="ovbNyugdijMegtakaritasOsszegKezdoHavi"
                          class="form-control"
                          min="15000"
                          max="100000"
                          step="1000"
                          :state="
                            validateState(
                              'ovbNyugdijMegtakaritasOsszegKezdoHavi'
                            )
                          "
                        ></b-form-input>
                      </b-col>
                      <b-col cols="4" class="text-right">
                        {{
                          $v.form.ovbNyugdijMegtakaritasOsszegKezdoHavi.$model
                        }}
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-form-row>
          </b-form>
        </div>

        <b-modal ref="error-modal" hide-footer title="Hiba történt!">
          <div class="d-block text-center">
            <h3>Hiba történt az űrlap beküldése során!</h3>
            <validation-error
              :errors="validationErrors"
              v-if="validationErrors"
            ></validation-error>
          </div>
          <b-button
            class="mt-3"
            variant="outline-danger"
            block
            @click="hideModal('error')"
            >Bezár</b-button
          >
        </b-modal>
      </div>

      <p class="my-4 font-weight-bold">
        Az értékek módosításával az eredmény újraszámolódik.
      </p>

      <div v-if="this.items" class="mt-5">
        <div class="h-100">
          <p class="font-weight-bold">
            A nyugdíjkorhatár elérésekor várható utolsó havi nettó jövedelem és
            első havi nyugdíj (infláció és bérnövekedés figyelembevétele nélkül)
          </p>
          <div class="row h-100">
            <div
              class="col-lg-2 text-center mx-auto mx-sm-0 bg-darkblue d-table"
            >
              <div class="d-table-cell align-middle">
                Utolsó nettó jövedelem
                <div class="mt-2 font-weight-bold">
                  {{
                    localeNumber(
                      this.keyResultBase.osszesJovedelemNyugdijbaVonulaskorHavi
                    )
                  }}
                  Ft
                </div>
              </div>
            </div>
            <div
              class="col-lg-2 text-center mx-auto mx-sm-0 bg-darkblue d-table"
            >
              <div class="d-table-cell align-middle">
                Első nyugdíj
                <div class="mt-2 font-weight-bold">
                  {{
                    localeNumber(
                      this.keyResultBase.varhatoNyugdijHaviKezdoKezdo
                    )
                  }}
                  Ft
                </div>
              </div>
            </div>
            <div class="col-lg-2 text-center mx-auto mx-sm-0 bg-pink d-table">
              <div class="d-table-cell align-middle">
                Megtakarítás nélkül
                <div class="mt-0 font-weight-bold">
                  {{
                    Math.round(
                      this.keyResultBase.nyugdijHianySzazalekMegtakaritasElott *
                        100 -
                        100
                    )
                  }}
                  %
                </div>
                nyugdíjhiány
              </div>
            </div>
            <div class="col-lg-2"></div>
            <div class="col-lg-2"></div>
            <div class="col-lg-2"></div>
          </div>
        </div>

        <div class="h-100">
          <p class="font-weight-bold mt-5">
            Jövedelem és nyugdíj alakulása (infláció és bérnövekedés figyelembe
            vételével)<sup>1</sup>
          </p>
          <div class="row align-items-center h-100">
            <div
              class="col-lg-2 text-center mx-auto mx-sm-0 mb-3 bg-darkblue d-table"
            >
              <div class="d-table-cell align-middle">
                Utolsó nettó jövedelem
                <div class="mt-2 font-weight-bold">
                  {{
                    localeNumber(
                      this.keyResult.osszesJovedelemNyugdijbaVonulaskorHavi
                    )
                  }}
                  Ft
                </div>
              </div>
            </div>
            <div
              class="col-lg-2 text-center mx-auto mx-sm-0 mb-3 bg-darkblue d-table"
            >
              <div class="d-table-cell align-middle">
                Első nyugdíj
                <div class="mt-2 font-weight-bold">
                  {{
                    localeNumber(this.keyResult.varhatoNyugdijHaviKezdoKezdo)
                  }}
                  Ft
                </div>
              </div>
            </div>
            <div
              class="col-lg-2 text-center mx-auto mx-sm-0 mb-3 bg-pink d-table"
            >
              <div class="d-table-cell align-middle">
                Megtakarítás nélkül
                <div class="mt-0 font-weight-bold">
                  {{
                    Math.round(
                      this.keyResult.nyugdijHianySzazalekMegtakaritasElott *
                        100 -
                        100
                    )
                  }}
                  %
                </div>
                nyugdíjhiány
              </div>
            </div>
            <div
              class="col-lg-2 text-center mx-auto mx-sm-0 d-table mb-3 bg-green"
            >
              <div class="d-table-cell align-middle">
                Havi
                <div class="mt-0 font-weight-bold">
                  {{ localeNumber(this.keyResult.megtakaritasOsszegKezdoHavi) }}
                  Ft
                </div>
                kezdő megtakarítás
              </div>
            </div>
            <div
              class="col-lg-2 text-center mx-auto mx-sm-0 d-table mb-3 bg-green"
            >
              <div class="d-table-cell align-middle">
                Megtakarítás
                <div class="mt-0 font-weight-bold">
                  {{ localeNumber(this.keyResult.megtakaritasZaroEgyenleg) }} Ft
                </div>
                ebből
                <div class="mt-0 font-weight-bold">
                  {{
                    localeNumber(
                      this.keyResult.megtakaritasSzjaKedvezmenyKumulalvaHozammal
                    )
                  }}
                  Ft
                </div>
                adókedvezmény
              </div>
            </div>

            <div
              class="col-lg-2 text-center mx-auto mx-sm-0 d-table mb-3 bg-darkblue"
            >
              <div class="d-table-cell align-middle">
                Megtakarítás után
                <div class="mt-0 font-weight-bold">
                  {{
                    Math.round(
                      this.keyResult.nyugdijHianySzazalekMegtakaritasUtan *
                        100 -
                        100
                    )
                  }}
                  %
                </div>
                nyugdíjhiány
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="mt-5 mb-5">
          Ön várhatóan {{ this.keyResult.nyugdijbaVonulasEv }}-ban/ben megy majd
          nyugdíjba és várható nyugdíja
          {{ this.localeNumber(this.keyResult.varhatoNyugdijHaviKezdoKezdo) }}
          Ft. Ez az utolsó jövedelmének körülbelül
          <b>{{
            this.keyResult.nyugdijHianySzazalekMegtakaritasElott * 100
          }}</b>
          százaléka.
          <div v-if="this.keyResult.megtakaritasOsszegKezdoHavi > 0">
            Amennyiben ma elkezd megtakarítani nyugdíjas éveire, úgy
            <b
              >{{ localeNumber(this.keyResult.megtakaritasOsszegKezdoHavi) }} Ft
              havi kezdő megtakarítás</b
            >
            mellett
            <span
              v-if="
                this.keyResult.nyugdijHianySzazalekMegtakaritasUtan > 0.999 &&
                  this.keyResult.nyugdijHianySzazalekMegtakaritasUtan < 1.001
              "
            >
              bevételeinek visszaesése teljesen elkerülhető!
            </span>
            <span
              v-else-if="
                this.keyResult.nyugdijHianySzazalekMegtakaritasUtan >= 1.001
              "
            >
              bevételei nőni fognak nyugdíjba vonuláskor (válasszon alacsonyabb
              összeget)!
            </span>
            <span v-else>
              bevételeinek visszaesése részben elkerülhető:
              <b
                >a visszaesés
                {{
                  this.keyResult.nyugdijHianySzazalekMegtakaritasUtan * 100
                }}%</b
              >.
            </span>
            <br />
            Nyugdíjba vonulásig összesen
            {{ localeNumber(this.keyResult.megtakaritasZaroEgyenleg) }} Ft-ot
            tud így felhalmozni, melyből
            {{
              localeNumber(
                this.keyResult.megtakaritasSzjaKedvezmenyKumulalvaHozammal
              )
            }}
            Ft az SZJA kedvezmény és annak hozamai.
          </div>
        </div> -->
        <div v-for="(item, index) in this.items" class="row mb-5" :key="index">
          <h3 class="font-weight-bold" v-html="chartTitles[index]"></h3>
          <apexchart
            :options="item"
            :series="item.series"
            style="width: 100%"
            :height="item.chart.height"
          ></apexchart>
        </div>

        <div v-html="this.paramText" />
      </div>
    </div>
    <section class="mt-5" ref="recall-wrapper">
      <RecallForm />
    </section>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { between, decimal, integer, required } from "vuelidate/lib/validators";
import CmsApiService from "@/services/CmsApiService";
import { SITE_TITLE } from "@/config";
import RecallForm from "@/components/partials/RecallForm";

export default {
  name: "Results",
  components: {
    apexchart: VueApexCharts,
    RecallForm,
  },
  data() {
    return {
      isVisible: false,
      form: {
        ovbNyugdijAktualisKor: 42,
        ovbNyugdijNem: 1,
        ovbNyugdijMunkakezdesKor: 21,
        ovbNyugdijBruttoBerHaviKezdo: 500000,
        ovbNyugdijEgyebJovedelemKezdoHavi: 0,
        ovbNyugdijDemografiaiSzorzo: 1,
        ovbNyugdijMegtakaritasSzjaKedvezmeny: 1,
        ovbNyugdijMegtakaritasJavaslat: 1,
        ovbNyugdijMegtakaritasOsszegKezdoHavi: 30000,
      },
      formData: {
        ovbNyugdijNemOptions: [
          { value: 1, text: "Férfi" },
          { value: 2, text: "Nő" },
        ],
        ovbNyugdijMegtakaritasSzjaKedvezmeny: [
          { value: 1, text: "Igen" },
          { value: 0, text: "Nem" },
        ],
      },
      validationErrors: "",
      items: null,
      formStorage: null,
      keyResult: null,
      paramText: null,
      keyResultBase: null,

      chartTitles: {
        jovedelemNyugdijMegtakaritasNelkul:
          "Havi jövedelmek alakulása megtakarítás nélkül <sup>2</sup>",
        jovedelemNyugdijMegtakaritassal:
          "Havi jövedelmek alakulása megtakarítással <sup>3</sup>",
        jovedelemNyugdijMegtakaritas: "Megtakarítás egyenlegének alakulása",
      },
      headerImage: "/assets/img/results.jpg",
    };
  },
  metaInfo() {
    return {
      title: "Az Ön nyugdíjszámítása | " + SITE_TITLE,
    };
  },

  validations: {
    form: {
      ovbNyugdijAktualisKor: {
        required,
        integer,
        between: between(23, 55),
      },
      ovbNyugdijNem: {
        required,
        integer,
        between: between(1, 2),
      },
      ovbNyugdijMunkakezdesKor: {
        required,
        integer,
        between: between(16, 30),
      },
      ovbNyugdijBruttoBerHaviKezdo: {
        required,
        integer,
        between: between(150000, 1500000),
      },
      ovbNyugdijEgyebJovedelemKezdoHavi: {
        required,
        integer,
        between: between(0, 1500000),
      },
      ovbNyugdijDemografiaiSzorzo: {
        required,
        decimal,
        between: between(0.07, 1),
      },
      ovbNyugdijMegtakaritasSzjaKedvezmeny: {
        required,
        integer,
        between: between(0, 1),
      },
      ovbNyugdijMegtakaritasJavaslat: {
        required,
        integer,
        between: between(0, 1),
      },
      ovbNyugdijMegtakaritasOsszegKezdoHavi: {
        required,
        integer,
        between: between(15000, 100000),
      },
    },
  },
  mounted() {
    if (localStorage.results) {
      this.loadData(localStorage.results);
      this.loadForm(localStorage.form);
    }
  },
  methods: {
    goto(refName) {
      let element = this.$refs[refName];
      let top = element.offsetTop;

      window.scrollTo(0, top);
    },
    toggle() {
      this.isVisible = !this.isVisible;
    },
    loadData(data) {
      let result = JSON.parse(data);
      this.items = result.charts;
      this.keyResult = result.keyResults;
      this.keyResultBase = result.keyResultsAlap;
      this.paramText = result.parameterSzoveg;
    },
    loadForm(data) {
      let form = JSON.parse(data);
      this.form.ovbNyugdijAktualisKor = form.ovbNyugdijAktualisKor;
      this.form.ovbNyugdijNem = form.ovbNyugdijNem;
      this.form.ovbNyugdijMunkakezdesKor = form.ovbNyugdijMunkakezdesKor;
      this.form.ovbNyugdijBruttoBerHaviKezdo =
        form.ovbNyugdijBruttoBerHaviKezdo;
      this.form.ovbNyugdijEgyebJovedelemKezdoHavi =
        form.ovbNyugdijEgyebJovedelemKezdoHavi;
    },
    localeNumber(number) {
      return number.toLocaleString("hu-HU");
    },
    showModal(ref) {
      this.$refs[ref + "-modal"].show();
    },
    hideModal(ref) {
      this.$refs[ref + "-modal"].hide();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    postForm: function() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      CmsApiService.postCalculatorForm(this.form).then((response) => {
        if (response.data.errors) {
          this.validationErrors = response.data.errors;
          this.showModal("error");
        } else {
          this.loadData(JSON.stringify(response.data));
        }
      });
    },
  },
};
</script>

<style scoped>
.inner-head {
  height: 285px;
}
</style>

<template>
  <div class="row">
    <div class="col-md-6">
      <div class="row counter-text align-items-center">
        <div class="col-3">
          <img src="assets/img/counter_icon.png" class="img-fluid"/>
        </div>
        <div class="col-9">
          Az elmúlt két évben
        </div>
      </div>
      <div class="numbers">
        <animated-number :value="number" :formatValue="formatNumber" :duration="duration"/>
      </div>
      <div class="counter-text text-right">
        nyugdíjcélú megtakarítás kiválasztásában és megkötésében tudtunk segíteni.
      </div>
    </div>
    <div class="col-md-6 family-story">
      <div class="pre">Hogy csinálták mások</div>
      <h2>Az öngondoskodás kifizetődik</h2>
      <p>
        Ismerje meg ügyfeleink történeteit, hogy az OVB hogyan tudott segíteni az optimális nyugdíjcélú öngondoskodási forma megszerzésében!
      </p>
      <router-link class="button" :to="this.$addHashParameter(this.url)">Nézze meg őket!</router-link>
    </div>
  </div>
</template>

<script>

import AnimatedNumber from "animated-number-vue";

export default {
  components: {
    AnimatedNumber
  },
  name: "Counter",
  data() {
    return {
      duration: 2000,
      url: "/sikertortenetek"
    };
  },
  props: {
    number: {type: [Number], required: true}
  },
  methods: {
    formatNumber(value) {
      return `${Number(value).toFixed(0)}`;
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="accordion indicator-plus-before round-indicator" role="tablist">
    <div v-if="this.items.length > 0">
      <p>Egy hosszú távú döntést meghozni nem egyszerű feladat. A mi fő profilunk 1970 óta az öngonsodkodás. A több mint
        50 éves piaci múltunk segít abban, hogy az OVB szakemberei szakszerűen bemutassák Önnek az öngondoskodás
        lehetőségeit, hogy valóban tudatos döntést hozhasson.</p>
      <b-card no-body class="mb-1" v-for="(item, index) in (this.items)" :key="index">
        <b-card-header collapsed header-tag="header" class="p-1" role="tab">
          <b-link block v-b-toggle="'accordion-' + index" variant="info" class="card-title">
            {{ item.name }}
          </b-link>
        </b-card-header>
        <b-collapse :id="'accordion-' + index" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text v-html="item.content"/>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>


<script>
import CmsApiService from "@/services/CmsApiService";

export default {
  name: "HomeAccordion",
  data() {
    return {
      items: "",
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      await CmsApiService.getContent('home-articles/5/0')
          .then(response => {
            if (response) {
              this.items = response.data;
            }
          });
    }
  }
}
</script>

<style scoped>

</style>
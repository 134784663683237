import Vue from "vue"
import App from "./App.vue"
import router from "./router";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueMeta from 'vue-meta';
import Vuelidate from 'vuelidate'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/css/style.css'
import sitePlugin from "@/plugins/sitePlugin";


Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuelidate)
Vue.use(VueMeta)
Vue.use(sitePlugin);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');


